var DDNewsletter;

module.exports = DDNewsletter = (function() {
  function DDNewsletter() {
    Breakpoints.on({
      name: "MOBILE_BREAKPOINT",
      matched: (function(_this) {
        return function() {
          return _this.matched_mobile.apply(_this, arguments);
        };
      })(this)
    });
    Breakpoints.on({
      name: "FROM_TABLET_BREAKPOINT",
      matched: (function(_this) {
        return function() {
          return _this.matched_tablet.apply(_this, arguments);
        };
      })(this)
    });
  }

  DDNewsletter.prototype.matched_mobile = function() {
    jQuery('.menu a[href*="dd-newsletter-form"]').unbind("click");
    jQuery('.icon-close').unbind("click");
    return jQuery('body').removeClass('overlay-is-active');
  };

  DDNewsletter.prototype.matched_tablet = function() {
    jQuery('.menu a[href*="dd-newsletter-form"]').click(function(e) {
      jQuery('body').addClass('overlay-is-active');
      return false;
    });
    return jQuery('.icon-close').click(function(e) {
      jQuery('body').removeClass('overlay-is-active');
      jQuery('.dd-newsletter--form').hide;
      return false;
    });
  };

  return DDNewsletter;

})();
