var Breakpoints, HeaderSearch, HeaderSearchWatcher;

Breakpoints = require('js-breakpoints');

HeaderSearch = (function() {
  function HeaderSearch($element) {
    this.$element = $element;
    this.$body = jQuery('body');
    this.$label = this.$element.find('label');
    this.$text = this.$element.find('.form-text');
    this.focused_class = 'is-focused';
    this.$label.on('click.dd_header_search', (function(_this) {
      return function(event) {
        event.preventDefault();
        return _this.$text.focus();
      };
    })(this));
    this.$element.on('click.dd_header_search', (function(_this) {
      return function(event) {
        event.stopPropagation();
        return _this.$element.addClass(_this.focused_class);
      };
    })(this));
    this.$body.on('click.dd_header_search', (function(_this) {
      return function(event) {
        return _this.$element.removeClass(_this.focused_class);
      };
    })(this));
  }

  HeaderSearch.prototype.destroy = function() {
    this.$label.off('.dd_header_search');
    this.$element.off('.dd_header_search');
    this.$body.off('.dd_header_search');
    return this.$element = this.$body = this.$label = this.$text = this.focused_class = null;
  };

  return HeaderSearch;

})();

module.exports = HeaderSearchWatcher = (function() {
  function HeaderSearchWatcher(selector) {
    this.selector = selector;
    this.instances = [];
    this.elements = jQuery(this.selector);
    Breakpoints.on({
      name: "FROM_TABLET_BREAKPOINT",
      matched: (function(_this) {
        return function() {
          return _this.matched_tablet.apply(_this, arguments);
        };
      })(this),
      exit: (function(_this) {
        return function() {
          return _this.exit_tablet.apply(_this, arguments);
        };
      })(this)
    });
  }

  HeaderSearchWatcher.prototype.matched_tablet = function() {
    return this.elements.each((function(_this) {
      return function(index, element) {
        return _this.instances[index] = new HeaderSearch(jQuery(element));
      };
    })(this));
  };

  HeaderSearchWatcher.prototype.exit_tablet = function() {
    var i, index, instance, len, ref;
    if (!this.instances.length) {
      return;
    }
    ref = this.instances;
    for (index = i = 0, len = ref.length; i < len; index = ++i) {
      instance = ref[index];
      instance.destroy();
    }
    return this.instances = [];
  };

  return HeaderSearchWatcher;

})();
