var Modernizr, Project, Projects;

Modernizr = require('../modernizr');

Project = (function() {
  var _path_fill_color_default, _path_fill_color_on;

  _path_fill_color_on = "#5b2283";

  _path_fill_color_default = "#E71D73";

  function Project(raphael_path1, id1, emitter) {
    this.raphael_path = raphael_path1;
    this.id = id1;
    this.emitter = emitter;
    this.is_active = false;
    this.is_hover = false;
    this.links = jQuery('[href*="' + this.id + '"]');
    this.wrapper = jQuery(this.id);
    this.container = this.wrapper.find('.projects--map-item-content');
    this.expand_sensitivity_area();
    this.events();
  }

  Project.prototype.expand_sensitivity_area = function() {
    if (this.raphael_path) {
      return this.raphael_path.attr({
        'stroke': 'black',
        'stroke-width': 15,
        'stroke-opacity': 0
      });
    }
  };

  Project.prototype.events = function() {
    if (this.raphael_path) {
      this.raphael_path.hover((function(_this) {
        return function() {
          return _this.hover_on();
        };
      })(this), (function(_this) {
        return function() {
          return _this.hover_off();
        };
      })(this)).click((function(_this) {
        return function() {
          return _this.toggle();
        };
      })(this));
      this.links.hover((function(_this) {
        return function() {
          return _this.hover_on();
        };
      })(this), (function(_this) {
        return function() {
          return _this.hover_off();
        };
      })(this));
      if (Modernizr.touchevents) {
        this.links.bind('touchstart', (function(_this) {
          return function() {
            return _this.hover_on();
          };
        })(this));
        this.links.bind('touchend', (function(_this) {
          return function() {
            return _this.hover_off();
          };
        })(this));
      }
    }
    this.links.click((function(_this) {
      return function(event) {
        event.preventDefault();
        return _this.toggle();
      };
    })(this));
    return this.wrapper.find('.js-close').click((function(_this) {
      return function(event) {
        return _this.deactivate();
      };
    })(this));
  };

  Project.prototype.hover_on = function() {
    if (this.is_hover) {
      return;
    }
    this.path_on();
    this.links.addClass('is-active');
    return this.is_hover = true;
  };

  Project.prototype.hover_off = function() {
    if (this.is_active) {
      return;
    }
    this.is_hover = false;
    this.path_off();
    return this.links.removeClass('is-active');
  };

  Project.prototype.path_on = function() {
    if (this.raphael_path) {
      if (this.raphael_path.dd_project_set != null) {
        this.raphael_path.dd_project_set.toFront();
      }
      return this.raphael_path.animate({
        fill: _path_fill_color_on
      }, 100);
    }
  };

  Project.prototype.path_off = function() {
    if (this.raphael_path) {
      return this.raphael_path.animate({
        fill: _path_fill_color_default
      }, 100);
    }
  };

  Project.prototype.activate = function() {
    this.is_active = true;
    this.path_on();
    this.links.addClass('is-active');
    return this.emitter.activate(this);
  };

  Project.prototype.deactivate = function() {
    this.is_active = false;
    this.path_off();
    this.links.removeClass('is-active');
    this.wrapper.append(this.container);
    this.container.removeClass('is-active');
    return this.emitter.deactivate(this);
  };

  Project.prototype.toggle = function() {
    if (this.is_active) {
      return this.deactivate();
    } else {
      return this.activate();
    }
  };

  Project.prototype.get_container = function() {
    return this.container;
  };

  return Project;

})();

Projects = (function() {
  function Projects() {
    if (Drupal.settings.dd_projects == null) {
      return;
    }
    Breakpoints.on({
      name: "MOBILE_BREAKPOINT",
      matched: (function(_this) {
        return function() {
          return _this.matched_mobile.apply(_this, arguments);
        };
      })(this)
    });
    Breakpoints.on({
      name: "FROM_TABLET_BREAKPOINT",
      matched: (function(_this) {
        return function() {
          return _this.matched_tablet.apply(_this, arguments);
        };
      })(this)
    });
  }

  Projects.prototype.matched_mobile = function() {
    this.items = jQuery('.projects--items');
    this.items.on('click.dd_projects', 'dt', function() {
      event.preventDefault();
      return jQuery(this).next().toggleClass('is-active');
    });
    this.items.on('click.dd_projects', '.js-close', function() {
      event.preventDefault();
      return jQuery(this).parents('dd').toggleClass('is-active');
    });
    return this.items.find('dd').eq(0).addClass('is-active');
  };

  Projects.prototype.matched_tablet = function() {
    if (Modernizr.touchevents) {
      jQuery('body').trigger('dreistHeaderNavigationCollapse');
    }
    this.container = jQuery('.projects--map-items');
    this.active_project = null;
    this.active_content = null;
    this.project_first = null;
    this.width_container = this.container.width();
    this.height_container = this.container.height();
    jQuery.each(Drupal.settings.dd_projects, (function(_this) {
      return function(id, raphael_path) {
        var _project;
        _project = new Project(dd_projects[raphael_path], id, _this);
        if (!_this.project_first) {
          return _this.project_first = _project;
        }
      };
    })(this));
    this.parent = this.container.parents('.projects--map, .projects');
    this.current_position = {
      left: 0,
      top: 0
    };
    this.draggable_initialize();
    this.project_first.activate();
    return this.cache_sets();
  };

  Projects.prototype.cache_sets = function() {
    var _i, _item, _path, _path_index, _set, _set_index, _sets, _term, raphael_path, ref, results;
    _sets = [];
    for (_i in dd_projects) {
      _item = dd_projects[_i];
      if (_item.toString() === "Raphaël‘s set") {
        _sets.push(_item);
      }
    }
    ref = Drupal.settings.dd_projects;
    results = [];
    for (_term in ref) {
      raphael_path = ref[_term];
      results.push((function() {
        var results1;
        results1 = [];
        for (_set_index in _sets) {
          _set = _sets[_set_index];
          results1.push((function() {
            var results2;
            results2 = [];
            for (_path_index in _set) {
              _path = _set[_path_index];
              if (_path === dd_projects[raphael_path]) {
                results2.push(_path.dd_project_set = _set);
              } else {
                results2.push(void 0);
              }
            }
            return results2;
          })());
        }
        return results1;
      })());
    }
    return results;
  };

  Projects.prototype.parent_height_update = function() {
    var headerHeight, viewPort, windowHeight;
    viewPort = Drupal.viewPort();
    windowHeight = viewPort[1];
    if (Modernizr.touchevents) {
      headerHeight = 70;
    } else {
      headerHeight = jQuery('.header').outerHeight();
    }
    return this.parent.height(windowHeight - headerHeight);
  };

  Projects.prototype.containment_calculate = function() {
    var _bounding_end_x, _bounding_end_y, _bounding_start_x, _bounding_start_y;
    this.parent_offset = this.parent.offset();
    this.current_width_parent = this.parent.width();
    this.current_height_parent = this.parent.height();
    _bounding_start_x = ((this.current_width_parent / 2) - this.width_container) + this.parent_offset.left;
    _bounding_start_y = ((this.current_height_parent / 2) - this.height_container) + this.parent_offset.top;
    _bounding_end_x = (this.current_width_parent / 2) + this.parent_offset.left;
    _bounding_end_y = (this.current_height_parent / 2) + this.parent_offset.top;
    return [_bounding_start_x, _bounding_start_y, _bounding_end_x, _bounding_end_y];
  };

  Projects.prototype.draggable_initialize = function() {
    this.parent_height_update();
    this.container.draggable({
      containment: this.containment_calculate(),
      start: (function(_this) {
        return function(evnet, ui) {
          if (_this.project_first.is_active) {
            _this.project_first.deactivate();
          }
          return _this.container.removeClass('is-animated');
        };
      })(this),
      stop: (function(_this) {
        return function(evnet, ui) {
          return _this.container.addClass('is-animated');
        };
      })(this),
      drag: (function(_this) {
        return function(event, ui) {
          return _this.current_position = ui.position;
        };
      })(this)
    });
    return jQuery(window).resize(jQuery.debounce((function(_this) {
      return function() {
        _this.parent_height_update();
        return _this.draggable_recalculate();
      };
    })(this), 300));
  };

  Projects.prototype.draggable_recalculate = function() {
    var _difference_x, _difference_y, _new_x, _new_y, _previous_height_parent, _previous_position, _previous_width_parent;
    if (!this.current_position) {
      return;
    }
    _previous_position = {
      left: this.current_position.left,
      top: this.current_position.top
    };
    _previous_width_parent = this.current_width_parent;
    _previous_height_parent = this.current_height_parent;
    this.container.draggable("option", "containment", this.containment_calculate());
    _difference_x = (this.current_width_parent - _previous_width_parent) / 2;
    _new_x = _previous_position.left + _difference_x;
    _difference_y = (this.current_height_parent - _previous_height_parent) / 2;
    _new_y = _previous_position.top + _difference_y;
    this.current_position = {
      left: _new_x,
      top: _new_y
    };
    return this.container_update_position();
  };

  Projects.prototype.container_update_position = function() {
    if (Modernizr.csstransitions) {
      return this.container.css(this.current_position);
    } else {
      return this.container.animate(this.current_position, 1000);
    }
  };

  Projects.prototype.activate = function(project) {
    var _new_x, _new_y, left, project_flyout, top;
    if (this.active_project) {
      this.active_project.deactivate();
    }
    this.active_project = project;
    project_flyout = this.active_project.get_container();
    this.container.append(project_flyout);
    left = parseInt(project_flyout.css("left"));
    top = parseInt(project_flyout.css("top"));
    left += project_flyout.outerWidth() / 2;
    top += project_flyout.outerHeight() / 2;
    _new_x = (this.current_width_parent / 2) - left;
    _new_y = (this.current_height_parent / 2) - top;
    if (this.current_position.left === _new_x && this.current_position.top === _new_y) {
      project_flyout.addClass('is-active');
    } else {
      setTimeout((function(_this) {
        return function() {
          return project_flyout.addClass('is-active');
        };
      })(this), 1000);
    }
    this.current_position = {
      left: _new_x,
      top: _new_y
    };
    return this.container_update_position();
  };

  Projects.prototype.deactivate = function(project) {
    if (this.active_project === project) {
      return this.active_project = null;
    }
  };

  return Projects;

})();

module.exports = Projects;
