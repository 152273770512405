var DDSlider, DDSliderWatcher, Modernizr;

require("bxSlider");

require('backstretch');

Modernizr = require('../modernizr');

DDSlider = (function() {
  function DDSlider(element) {
    this.slider = jQuery(element);
    this.pager = this.slider.find('.bx-pager');
    this.images = this.slider.find('.slider--images img');
    this.sliderItems = this.slider.find('.slider--images li');
    Breakpoints.on({
      name: "MOBILE_BREAKPOINT",
      matched: (function(_this) {
        return function() {
          return _this.matched_mobile.apply(_this, arguments);
        };
      })(this)
    });
    Breakpoints.on({
      name: "FROM_TABLET_BREAKPOINT",
      matched: (function(_this) {
        return function() {
          return _this.matched_tablet.apply(_this, arguments);
        };
      })(this)
    });
  }

  DDSlider.prototype.backstretch_create = function() {
    if (Modernizr.bgsizecover) {
      return;
    }
    this.images_cache = [];
    return this.images.each((function(_this) {
      return function(index, image) {
        var $image, _src;
        _src = image.src;
        $image = jQuery(image);
        _this.images_cache[index] = $image;
        _this.sliderItems.eq(index).backstretch(_src);
        return $image.remove();
      };
    })(this));
  };

  DDSlider.prototype.backstretch_destroy = function() {
    var $image, index, ref, ref1;
    if (Modernizr.bgsizecover) {
      return;
    }
    if (!((ref = this.images_cache) != null ? ref.length : void 0)) {
      return;
    }
    ref1 = this.images_cache;
    for (index in ref1) {
      $image = ref1[index];
      this.sliderItems.eq(index).backstretch("destroy", false).append($image);
    }
    return this.images_cache = [];
  };

  DDSlider.prototype.create_slider = function() {
    if (this.slider_is_created != null) {
      return;
    }
    this.slider_is_created = true;
    return this.bxSlider = this.slider.find('.slider--images').bxSlider({
      mode: 'horizontal',
      controls: true,
      auto: true,
      autoStart: true,
      autoHover: true,
      adaptiveHeight: true,
      speed: 1000,
      useCSS: false,
      prevText: 'Zurück',
      nextText: 'Vor',
      onSlideBefore: (function(_this) {
        return function($slideElement, oldIndex, newIndex) {
          return $slideElement.siblings().height(_this.sliderHeight);
        };
      })(this)
    });
  };

  DDSlider.prototype.matched_mobile = function() {
    this.backstretch_destroy();
    this.height_destroy();
    return this.create_slider();
  };

  DDSlider.prototype.matched_tablet = function() {
    this.backstretch_create();
    this.create_slider();
    this.window = this.window || jQuery(window);
    this.header = this.header || jQuery('.header');
    this.wrapper = this.wrapper || this.slider.find('.bx-wrapper');
    this.viewport = this.viewport || this.slider.find('.bx-viewport');
    this.height = this.height || jQuery([]).add(this.wrapper).add(this.viewport).add(this.sliderItems);
    this.window.bind('resize.dd_slider', (function(_this) {
      return function() {
        return _this.height_update();
      };
    })(this));
    return this.height_update();
  };

  DDSlider.prototype.height_update = function() {
    var headerHeight, pagerHeight, viewPort, windowHeight;
    viewPort = Drupal.viewPort();
    windowHeight = viewPort[1];
    headerHeight = this.header.outerHeight();
    pagerHeight = this.pager.outerHeight();
    this.sliderHeight = Math.round((windowHeight * 0.75) - headerHeight - pagerHeight);
    if (this.sliderHeight < 560) {
      this.sliderHeight = 560;
    }
    return this.height.height(this.sliderHeight);
  };

  DDSlider.prototype.height_destroy = function() {
    if (this.height == null) {
      return;
    }
    this.window.unbind('.dd_slider');
    return this.height.each(function() {
      return this.style.height = 'auto';
    });
  };

  return DDSlider;

})();

module.exports = DDSliderWatcher = (function() {
  function DDSliderWatcher() {
    jQuery('.slider').once('slider', function(index, element) {
      return new DDSlider(element);
    });
  }

  return DDSliderWatcher;

})();
