var Quote;

Quote = (function() {
  function Quote(selector) {
    var _random_element;
    this.selector = selector;
    this.elements = jQuery(this.selector);
    _random_element = this.elements[Math.floor(Math.random() * this.elements.length)];
    jQuery(_random_element).addClass('is-active');
  }

  Quote.prototype.destroy = function() {
    if (this.elements == null) {
      return;
    }
    return this.elements.quote("destroy");
  };

  return Quote;

})();

module.exports = Quote;
