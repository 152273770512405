var Accordion, Breakpoints, DDQuote, DDSlider, HeaderNavigation, HeaderSearch, MatchHeight, Modernizr, Tabs, TabsTouch, fastclick;

Modernizr = require('./modernizr');

require("requestAnimationFrame");

require("jquery-debounce");

require("jquery-easing");

require('jquery-transitionend');

require('jquery-ui-touch-punch-improved');

fastclick = require('fastclick');

Breakpoints = require('js-breakpoints');

HeaderSearch = require('./components/header-search');

HeaderNavigation = require('./components/header-navigation');

MatchHeight = require("./components/matchheight");

DDSlider = require('./components/dd_slider');

Accordion = require('./components/accordion');

Tabs = require('./components/tabs');

TabsTouch = require('./components/tabs');

DDQuote = require('./components/dd_quote');

require("../../../../../bower_components/bxslider-4/jquery.bxslider.js");

Drupal.viewPort = function() {
  var viewPortHeight, viewPortWidth;
  viewPortWidth = void 0;
  viewPortHeight = void 0;
  if (typeof window.innerWidth !== "undefined") {
    viewPortWidth = window.innerWidth;
    viewPortHeight = window.innerHeight;
  } else if (typeof document.documentElement !== "undefined" && typeof document.documentElement.clientWidth !== "undefined" && document.documentElement.clientWidth !== 0) {
    viewPortWidth = document.documentElement.clientWidth;
    viewPortHeight = document.documentElement.clientHeight;
  } else {
    viewPortWidth = document.getElementsByTagName("body")[0].clientWidth;
    viewPortHeight = document.getElementsByTagName("body")[0].clientHeight;
  }
  return [viewPortWidth, viewPortHeight];
};

Drupal.behaviors.dd_theme_dreist = {
  attach: function() {
    return fastclick.attach(document.body);
  }
};

Drupal.behaviors.header_search = {
  attach: function() {
    return new HeaderSearch('.search--form-header');
  }
};

Drupal.behaviors.header_navigation = {
  attach: function() {
    return new HeaderNavigation();
  }
};

Drupal.behaviors.match_height = {
  attach: function() {
    return new MatchHeight({
      footer: '.footer .section--module'
    });
  }
};

Drupal.behaviors.dd_tabs = {
  attach: function() {
    return new Tabs('.js-tabs');
  }
};

Drupal.behaviors.dd_theme_tabs_touch = {
  attach: function() {
    return new TabsTouch('.js-tabs-touch');
  }
};

Drupal.behaviors.dd_quote = {
  attach: function() {
    return new DDQuote('.view-id-dd_quotes_random .views-row');
  }
};

Drupal.behaviors.theme_external = {
  attach: function() {
    var host;
    host = window.location.host;
    return jQuery('a').once('theme_external', function() {
      if (this.href.length < 2) {
        return;
      }
      if (this.href.indexOf(host) !== -1) {
        return;
      }
      if (this.href.indexOf('mailto') !== -1) {
        return;
      }
      this.target = '_blank';
      return this.className += " link--external";
    });
  }
};

Drupal.behaviors.theme_dd_slider = {
  attach: function() {
    return new DDSlider();
  }
};

Drupal.behaviors.theme_dd_projects = {
  attach: function() {
    var Projects;
    Projects = require('./components/dd_project');
    return new Projects();
  }
};

Drupal.behaviors.follower_links = {
  attach: function() {
    var _toplink, _visible, _window;
    _visible = false;
    _toplink = jQuery('.js--toplink-flag');
    _window = jQuery(window);
    _window.scroll(function(event) {
      var _scrollY;
      _scrollY = _window.scrollTop();
      return window.requestAnimationFrame((function(_this) {
        return function() {
          if (_scrollY > 700 && _visible === false) {
            _toplink.addClass('is-active');
            _visible = true;
          }
          if (_scrollY < 700 && _visible) {
            _toplink.removeClass('is-active');
            return _visible = false;
          }
        };
      })(this));
    });
    return jQuery('.js--toplink-flag').click(function() {
      jQuery("html,body").animate({
        scrollTop: 0
      }, 500);
      return false;
    });
  }
};

Drupal.behaviors.theme_dd_newsletter = {
  attach: function() {
    var DDNewsletter;
    DDNewsletter = require('./components/dd_newsletter');
    return new DDNewsletter();
  }
};

Drupal.behaviors.dd_theme_accordion = {
  attach: function() {
    return new Accordion('.js-accordion');
  }
};

Drupal.behaviors.dd_layout_carousel = {
  attach: function() {
    return jQuery('.dd-carousel .view-content').once('dd-carousel', function() {
      var $wrapper, _bx_slider_settings, _max_slides, _width_item, _width_wrapper;
      $wrapper = jQuery(this);
      _width_wrapper = $wrapper.width();
      _width_item = $wrapper.children(':first-child').width();
      _max_slides = Math.ceil(_width_wrapper / _width_item);
      _bx_slider_settings = {
        infiniteLoop: false,
        pager: false
      };
      if (_max_slides > 1) {
        _bx_slider_settings = {
          minSlides: 1,
          maxSlides: _max_slides,
          slideWidth: _width_item,
          infiniteLoop: false,
          pager: false
        };
      }
      return $wrapper.bxSlider(_bx_slider_settings);
    });
  }
};
