var MatchHeight, MatchHeightWatcher;

require("matchHeight");

MatchHeight = (function() {
  function MatchHeight(settings1) {
    this.settings = settings1;
    jQuery.fn.matchHeight._throttle = 200;
    this.$footer = jQuery(settings.footer);
    this.$footer.matchHeight();
    jQuery.fn.matchHeight._beforeUpdate = (function(_this) {
      return function(event, groups) {
        return _this.main_menu_update('remove', groups);
      };
    })(this);
    jQuery.fn.matchHeight._afterUpdate = (function(_this) {
      return function(event, groups) {
        return _this.main_menu_update('add', groups);
      };
    })(this);
    setTimeout(function() {
      return jQuery.fn.matchHeight._update();
    }, 250);
  }

  MatchHeight.prototype.destroy = function() {
    this.$footer.matchHeight('remove');
    return this.$footer = null;
  };

  return MatchHeight;

})();

module.exports = MatchHeightWatcher = (function() {
  function MatchHeightWatcher(settings1) {
    this.settings = settings1;
    this.instances = [];
    Breakpoints.on({
      name: 'FROM_MOBILE_BREAKPOINT',
      matched: (function(_this) {
        return function() {
          return _this.matched.apply(_this, arguments);
        };
      })(this),
      exit: (function(_this) {
        return function() {
          return _this.exit.apply(_this, arguments);
        };
      })(this)
    });
  }

  MatchHeightWatcher.prototype.matched = function() {
    return this.instances[0] = new MatchHeight(this.settings);
  };

  MatchHeightWatcher.prototype.exit = function() {
    var i, index, instance, len, ref;
    if (!this.instances.length) {
      return;
    }
    ref = this.instances;
    for (index = i = 0, len = ref.length; i < len; index = ++i) {
      instance = ref[index];
      instance.destroy();
    }
    return this.instances = [];
  };

  return MatchHeightWatcher;

})();
