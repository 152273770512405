require('browsernizr/test/css/transitions');

require('browsernizr/test/css/backgroundsizecover');

require('browsernizr/test/touchevents');

require('browsernizr/test/css/transforms');

require('browsernizr/test/css/mediaqueries');

require('browsernizr/test/css/opacity');

require('browsernizr/test/svg');

module.exports = require('browsernizr');
