var Breakpoints, Modernizr, Navigation;

Breakpoints = require('js-breakpoints');

Modernizr = require('../modernizr');

Navigation = (function() {
  function Navigation() {
    this.body = jQuery('body');
    this.body.once('js-navigation', (function(_this) {
      return function(i, element) {
        _this.lastScroll = jQuery(window).scrollTop();
        _this.threshold = 80;
        _this.stickyStateLower = _this.lastScroll > _this.threshold;
        _this.headerToggleVisible = true;
        _this.scrollShouldBeUpdated = true;
        _this.navigationActive = false;
        _this.scrollInit(_this.lastScroll);
        _this.headerSticky();
        _this.body.bind('dreistHeaderNavigationCollapse', function() {
          return _this.body.addClass('header-is-forced-sticky');
        });
        _this.breakpoint_element = jQuery('.header');
        Breakpoints.on({
          name: "TO_MOBILE_NAVIGATION_BREAKPOINT",
          matched: function() {
            return _this.matched_mobile.apply(_this, arguments);
          },
          el: _this.breakpoint_element[0]
        });
        return Breakpoints.on({
          name: "FROM_MOBILE_NAVIGATION_BREAKPOINT",
          matched: function() {
            return _this.matched_tablet.apply(_this, arguments);
          },
          el: _this.breakpoint_element[0]
        });
      };
    })(this));
  }

  Navigation.prototype.matched_mobile = function() {
    this.threshold = 135;
    if (this.executed_mobile != null) {
      return;
    }
    this.executed_mobile = true;
    this.touchTrigger();
    return this.touchNavigationMobile();
  };

  Navigation.prototype.matched_tablet = function() {
    if (this.executed_tablet != null) {
      return;
    }
    this.executed_tablet = true;
    this.overlay = jQuery('.navigation--overlay');
    this.overlay.click((function(_this) {
      return function() {
        return _this.overlay_trigger.mouseleave();
      };
    })(this));
    this.overlay_trigger = jQuery('.region-header-navigation').hover((function(_this) {
      return function() {
        return _this.overlay.addClass('is-active');
      };
    })(this), (function(_this) {
      return function() {
        return _this.overlay.removeClass('is-active');
      };
    })(this));
    return this.touchNavigationTablet();
  };

  Navigation.prototype.scrollInit = function() {
    var _window;
    _window = jQuery(window);
    return _window.scroll((function(_this) {
      return function(event) {
        var _scrollY;
        _scrollY = _window.scrollTop();
        return window.requestAnimationFrame(function() {
          _this.headerSticky(_scrollY);
          if (Modernizr.touchevents) {
            if (!_this.navigationActive) {
              _this.headerToggle(_scrollY);
            }
          }
          return _this.lastScroll = _scrollY;
        });
      };
    })(this));
  };

  Navigation.prototype.headerSticky = function(_scrollY) {
    if (_scrollY > this.threshold && !this.stickyStateLower) {
      this.scrollShouldBeUpdated = true;
    }
    if (_scrollY < 135 && this.stickyStateLower) {
      this.scrollShouldBeUpdated = true;
    }
    if (this.scrollShouldBeUpdated) {
      this.scrollShouldBeUpdated = false;
      this.stickyStateLower = _scrollY > this.threshold;
      if (this.stickyStateLower) {
        return this.body.addClass('header-is-sticky');
      } else {
        return this.body.removeClass('header-is-sticky');
      }
    }
  };

  Navigation.prototype.headerToggle = function(_scrollY) {
    if (_scrollY > this.lastScroll && this.headerToggleVisible) {
      this.scrollShouldBeUpdated = true;
    }
    if (_scrollY < this.lastScroll && !this.headerToggleVisible) {
      this.scrollShouldBeUpdated = true;
    }
    this.headerToggleVisible = _scrollY > this.lastScroll;
    if (this.scrollShouldBeUpdated) {
      this.scrollShouldBeUpdated = false;
      if (this.headerToggleVisible) {
        return this.body.addClass('header-is-hidden');
      } else {
        return this.body.removeClass('header-is-hidden');
      }
    }
  };

  Navigation.prototype.touchTrigger = function() {
    return jQuery('.navigation--mobile-trigger').click((function(_this) {
      return function(event) {
        event.preventDefault();
        if (_this.body.hasClass('navigation--mobile-active')) {
          _this.body.removeClass('navigation--mobile-active');
          _this.navigationActive = false;
          return jQuery('.header, .header--logo-wrapper').unbind('.touchTrigger');
        } else {
          _this.body.addClass('navigation--mobile-active');
          _this.navigationActive = true;
          return jQuery('.header, .header--logo-wrapper').bind('touchmove.touchTrigger', function(event) {
            return event.stopPropagation();
          });
        }
      };
    })(this));
  };

  Navigation.prototype.touchNavigationTablet = function() {
    var _document, _self, menuItems;
    if (!Modernizr.touchevents) {
      return;
    }
    menuItems = jQuery('.navigation--main .navigation--menu').prev();
    _document = jQuery(document);
    _document.unbind('click.touchNavigation');
    menuItems.unbind('.touchNavigation');
    _self = this;
    menuItems.bind('click.touchNavigation', function(event) {
      var $li, $link;
      $link = jQuery(this);
      $li = $link.parent();
      if ($li.hasClass('menu--projekte')) {
        return true;
      }
      $li.siblings('.hover').removeClass('hover');
      $li.toggleClass('hover');
      if (!$li.hasClass('hover')) {
        _self.overlay_trigger.mouseleave();
      } else {
        _self.overlay_trigger.mouseenter();
      }
      event.preventDefault();
      return false;
    });
    return _document.bind('click.touchNavigation', (function(_this) {
      return function() {
        menuItems.parents('.hover').removeClass('hover');
        return _this.overlay_trigger.mouseleave();
      };
    })(this));
  };

  Navigation.prototype.touchNavigationMobile = function() {
    var _document, menuItems;
    if (!Modernizr.touchevents) {
      return;
    }
    menuItems = jQuery('.navigation--main .navigation--menu').prev();
    _document = jQuery(document);
    _document.unbind('click.touchNavigation');
    menuItems.unbind('.touchNavigation');
    return menuItems.bind('click.touchNavigation', function(event) {
      var $li, $link;
      $link = jQuery(this);
      $li = $link.parent();
      $li.siblings('.active-trail').removeClass('active-trail');
      $li.toggleClass('active-trail');
      return event.preventDefault();
    });
  };

  return Navigation;

})();

module.exports = Navigation;
