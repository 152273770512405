var Accordion;

Accordion = (function() {
  function Accordion(selector) {
    if (!jQuery.fn.accordion) {
      return;
    }
    jQuery(selector).accordion({
      header: '.accordion--title',
      heightStyle: 'content',
      animate: true,
      active: false,
      collapsible: true
    });
  }

  return Accordion;

})();

module.exports = Accordion;
