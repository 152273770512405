var Tabs;

Tabs = (function() {
  function Tabs(selector) {
    this.selector = selector;
    if (jQuery.fn.tabs == null) {
      return;
    }
    this.elements = jQuery(this.selector);
    this.elements.once('js-tabs', (function(_this) {
      return function(i, element) {
        return jQuery(element).tabs({
          beforeLoad: function(event, ui) {
            window.location = ui.ajaxSettings.url;
            return false;
          }
        });
      };
    })(this));
  }

  Tabs.prototype.destroy = function() {
    if (this.elements == null) {
      return;
    }
    return this.elements.tabs("destroy");
  };

  return Tabs;

})();

module.exports = Tabs;
